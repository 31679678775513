<template>
<div>
  <div align="center"  class="content-item">
    <v-img
    class="image-head"
      :src="require('@/assets/premiummember/detail-promotion.png')">
    </v-img>
    <v-container>

      <notification-popup  
      :dialog="notifSuccess"
      contentMsg="Dialog content here. Dialog content here."
      headerMsg="Successful Promotion Register"
      nameBtnYes="Back To Promotion"
      disableBtnNo="true"
      colorIcon = "success"
      typeModal="transaction"
      Icon="mdi-check-circle"
      :onHandlerNo="cancelSuccess"
      :onHandlerYes="buttonOke"/>


      <notification-popup 
      :dialog="notifFailed"
      :contentMsg="$t('failed')"
      :headerMsg="$t('failed')"
      :nameBtnNo="$t('labelNo')"
      :nameBtnYes="$t('labelYes')"
      colorIcon = "danger"
      typeModal="transaction"
      Icon="mdi-close-circle"
      :onHandlerNo="cancelFailed"
      :onHandlerYes="buttonOkeFailed"/>
    
    <notification-popup 
      :dialog="dialogApplyPromotion"
      contentMsg="Lorem ipsum dolor sit amet consectetur adipisicing elit."
      headerMsg="Dialog Tile"
      colorheaderMsg="black--text"
      nameBtnNo="No"
      nameBtnYes="Yes"
      colorIcon = "danger"
      :onHandlerYes="clickApplyPromotion"/>
      <p class="title-promo">{{items_promo.promo_name}} - {{$t("Discount")}} {{items_promo.promo_value}}</p>
      <p class="describe-promo">Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero perspiciatis numquam repellendus atque, sunt ab dolorum inventore eum voluptas cum eos deleniti eligendi nam veritatis voluptatibus itaque asperiores qui unde.</p>
      
      <br><br><br><br>
      <div class="footer" v-if="$route.params.type == 'available'">
        <v-container>
          <v-btn @click="clickDialogAppy" class="btn primary" rounded width="100%" > {{$t("Apply")}} </v-btn>
        </v-container>
      </div>
      </v-container>
    </div>
    </div>
</template>

<script>
import randomstring from 'randomstring'
import NotificationPopup from "@/components/NotificationPopup.vue";

  export default {
    name:"DetailPromotion",
    components: { NotificationPopup},
    data() {
      return {
        dialogApplyPromotion:false,
        notifSuccess:false,
        notifFailed:false,
        items_promo:{},
        tab: null,
        items: [
          'Available', 'Active',
        ],
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      }
    },
    methods:{
      clickDialogAppy(){
        this.dialogApplyPromotion = true
      },
      clickApplyPromotion(){
        let random = randomstring.generate({
          length: 6,
          charset: 'alphabetic',
          capitalization:'uppercase',
        });

        const payload = {
            promo_id:this.items_promo.promo_id,
            random_code:random,
        };

        this.$store
        .dispatch("passenger/createPromotionProgram", payload)
        .then((response) => {
          if (response.status == true) {
            this.notifSuccess = true
          }
        })
        .catch((err) => {
          console.log(err);
        });

      },
      buttonOke(){
        this.notifSuccess = false
        if (this.notifSuccess == false) this.$router.push({ name: "PromotionProgram" });
      },
      buttonOkeFailed(){
        this.notifFailed = false
      },
      
      cancelFailed(){
        this.notifFailed=false;
      },
      cancelSuccess(){
        this.notifSuccess=false;
      },
    },
    mounted(){
      // console.log('params',this.$route)
      this.items_promo = this.$route.params.items
    }
  }
</script>


<style lang="scss" scoped>


/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  .image-head{
    width: 60%;
    height: 40%;
  }

  .content-item{
    background-color: #FFFFFF;
    margin-top: 1%;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  .title-promo{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: capitalize;

    color: rgba(0, 0, 0, 0.85);
  }


  .describe-promo{
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    text-align: justify;

    color: rgba(0, 0, 0, 0.85);
  }

.footer {
  margin-top: 25px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: #FFFFFF;
  mix-blend-mode: normal;
  /* Field */

  // box-shadow: 0px 1px 10px 0px
}

.btn{

  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}
}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
  .image-head{
    width: auto;
    height: 100%;
  }

  .content-item{
    margin-top: 2%;
    background-color: #FFFFFF;
    width: 100%;
    height: 100%;
    bottom: 0;
  }


  .title-promo{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: capitalize;

    color: rgba(0, 0, 0, 0.85);
  }


  .describe-promo{
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    text-align: justify;

    color: rgba(0, 0, 0, 0.85);
  }

.footer {
  margin-top: 25px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: #FFFFFF;
  mix-blend-mode: normal;
  /* Field */

  // box-shadow: 0px 1px 10px 0px
}
.btn{

  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}

}
</style>